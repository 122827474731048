import React from "react"
import { Link } from "gatsby"

import "./Header.css"

import wealthbaskets_logo from "../../images/wealthbaskets-logo.svg"

class Header extends React.Component {
  render() {
    return (
      <>
        <div className="techBlogNav">
          <header>
            <div className="techBlogNav__logo">
              <Link to="/">
                <img src={wealthbaskets_logo} alt="WealthBasket Logo" />
              </Link>
            </div>
            <div>
              <Link className="techBlogNav__title" to="/">
                | Blog
              </Link>
            </div>
          </header>
        </div>
      </>
    )
  }
}

export default Header
